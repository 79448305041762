.pane-resources {
  .resource {
    color: black;
    padding: 20px;

    .image-wrapper {
      height: 120px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 5px;
      margin-bottom: 20px;

      img { max-height: 120px; }
    }

    p {
      font-size: 16px;
      margin-bottom: 0;
    }

    .button { display: block; }
    .button:last-child { margin-bottom: 0}
  }
}

.organization-classification {
  margin-top: 30px;
}
