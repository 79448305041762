html {
  background: url(../img/bg6.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  background: transparent;
  min-height: 100vh;
}

.home-wrapper {
  height: 100%;
  min-height: calc(100vh - 250px);
  padding-top: 40px;

  @include breakpoint(small down) {
    height: auto;
    min-height: auto;
  }

  .centered-wrapper {
    min-height: calc(100vh - 250px);

    @include breakpoint(medium only) {
      min-height: calc(100vh - 360px);
    }

    @include breakpoint(small down) {
      min-height: inherit;
    }
  }
}

.main {
  color: $white;
  background: rgba(0,0,0,.6);
  padding: 25px;
  border-radius: 10px;

  .header{
    margin-bottom: 20px;
  }

  .results {
    .agency {
      margin-bottom: 30px;

      .large-9.cell {
        padding: 20px;

        &.white {
          color: $black;
        }

        &.grey {
          background-color: rgba(255,255,255, .1);
        }
      }

      .large-3.cell {
        border: 0;
      }

      .left {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .right {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .agency-buttons {
        margin-top: 20px;
        .button { margin-bottom: 0; }
      }

      .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0 20px;

        img { margin: 5px 0; }
      }

      p { margin-bottom: 0; }
    }
  }

  .footer {
    margin-top: 25px;

    a { margin-bottom: 0; }
  }
}

// Menu
.top-bar {
  font-family: $source-sans;

  a {
    color: $white;
    font-size: 20px;

    &.active {
      background: none !important;
      font-weight: bold;
      color: $menu-active;
    }
  }

  .top-bar-left {
    .logo {
      height: 100px;
      border-right: 1px solid $white;
      padding-right: 25px;
      margin-right: 15px;
    }
  }

  @include breakpoint(medium down) {
    background-color: rgba(0, 0, 0, 0.25);

    .menu {
      text-align: center;

      a {
        font-size: 16px;
        border-top: 1px dashed $white;
      }
      li:first-child {
        a { border: 0; }
      }
    }
  }

  @include breakpoint(small down) {
    .top-bar-left {
      .logo { display: none; }
      .site-name {
        font-weight: bold;
        font-family: $work-sans;
        text-align: center;
        font-size: 16px;
        display: block;
      }
    }
  }
}

// Buttons
.button {
  font-weight: bold;
  border-radius: 3px;
}

// Typography

h1, h2, h3 {
  color: $white;
  font-family: $work-sans;
  font-weight: bold;
  line-height: 1;

  span {
    font-weight: normal;
  }
}

h4 {
  font-family: $work-sans;
  font-weight: bold;
}

p {
  font-family: $source-sans;
  font-size: 20px;
}

.error-wrapper {
  padding-top: 15px;
  margin-bottom: 0;

  li { color: $primary-color; }
}

// Helpers
.default-spacing {
  margin-bottom: 30px;
}

.justify-center {
  justify-content: center;
}

.cell {
  &.white { background: rgba(255,255,255,.85); }
  &.rounded {  border-radius: 10px; }
}

#header-content {
  background: rgba(255,255,255,.8);
  padding: 25px;
  border-radius: 10px;

  h2{
    color: $black;
    text-align: center;
  }

  .close {
    color: $black;
    cursor: pointer;
    float:right;
    position: relative;
    top: -10px;
    display:inline-block;
    padding:2px 5px;

    &:hover {
      color: $button-primary;
    }
  }
}
